$(function () {
    var isInit = true;
    var directoryLoadedOnPageLoad = false;

    //var showAddToTripPopUp = function(isPlace, contentId, selector) {
    //    //console.log("Place directory favorites");
    //    wdot.Favorites.IsPlace = isPlace;
    //    wdot.Favorites.ContentId = contentId
    //    $('#AddToGroups').data('isselectable', 'true');
    //    wdot.TripPlannerAddToGroup.TripGroupViewModel().GetTripGroups(contentId, isPlace);
    //    wdot.TripPlannerAddToGroup.FavoriteSelector(selector);
    //    // Adding custom event that popup-position.js relies on

    //    var e = $.Event('call-popup', { activeItem: selector });
    //    selector.trigger(e);
    //};

    /*var showAddToTripPopUp = function(contentType, contentId, selector) {
        //console.log("Place directory favorites");
        //wdot.Favorites.IsPlace = isPlace;
        wdot.Favorites.ContentId = contentId;
        $('#AddToGroups').data('isselectable', 'true');
        wdot.TripPlannerAddToGroup.TripGroupViewModel().GetTripGroups(contentId, contentType);
        wdot.TripPlannerAddToGroup.FavoriteSelector(selector);
        // Adding custom event that popup-position.js relies on

        var e = $.Event('call-popup', { activeItem: selector });
        selector.trigger(e);
    };*/

    /*var showLoginOrTripGroupPopUp = function(isUserLoggedIn, contentType, dataId, currentSelector, sessionUserPopUpSelector) { 
        //var $this = $(this);
        //var isUserLoggedIn = $('.js-user-logged-in').val().trim();
		console.log(isUserLoggedIn);
        if (!$.cookie('sessionLoginConfirm') && isUserLoggedIn === "false") {
            //var favoritePrompt = $(this).parents('.directoryGrid__content-image').find('.js-session-favorite-prompt');
            //var favoritePrompt = currentSelector.parents('.directoryGrid__content-image').find('.js-session-favorite-prompt');
            //favoritePrompt.removeClass('hidden');

            sessionUserPopUpSelector.removeClass('hidden');
        } else {
            //showAddToTripPopUp(false, $(this).parents("li").attr("data-id"), $(this));
            showAddToTripPopUp(contentType, dataId, currentSelector);
        }


        currentSelector.parents('.js-favorites-parent').find('.js-session-favorite-login').click(function () {
            $('.login').find($('.global-nav__dropdown')).show();
            $('html, body').animate({ scrollTop: 0 }, '1500', 'easeOutCubic', function() {});
            $(this).parent().addClass('hidden');
        });

        currentSelector.parents('.js-favorites-parent').find('.js-session-favorite-cancel').click(function () {
            $(this).parent().addClass('hidden');
            $.cookie('sessionLoginConfirm', 'true', { expires: 1 });
            //showAddToTripPopUp(false, $(this).parents("li").attr("data-id"), $(this).parents('.directoryGrid__event-item').find('.js-favorites'));
            showAddToTripPopUp(contentType, dataId, currentSelector.parents('.js-favorites-parent'));
        });
    };*/

    var loadDirectory = function (e){
        if(e==undefined) e = {value :window.location.hash};
        var selectedTabUriEncodedName = encodeURI($(".contentNav ul li.active a").attr("title"));
        if ((e.value == "" || e.value == "/") && selectedTabUriEncodedName != encodeURI("Overview") && $("[data-tabname]").data("tabname") == "Overview") {
            $(".contentNav ul li").removeClass("active");
            $(".contentNav ul li a[title=Overview]").parent().addClass("active");
            $("#listingContainer").addClass("hidden");
            $("#overview").removeClass("hidden");
            $.each(ajaxArray, function (item) {
                this.abort();
            });
        } else {
            if (!isInit||(e.value != "" && e.value != "/")||(selectedTabUriEncodedName != encodeURI($("[data-tabname]").data("tabname"))&&(e.value==""||e.value=="/"))) {
                var pageType = (selectedTabUriEncodedName != encodeURI($("[data-tabname]").data("tabname"))&&(e.value==""||e.value=="/"))?$("[data-tabname]").data("tabname"):decodeURI(e.value.split("?")[0].replace("/", ""));
                if(pageType!=undefined) {
                    pageType = pageType.replace('#','');
                    // make camel case
                    pageType = pageType.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
                }
                var ajaxUrl = '';

                $(".contentNav ul li").removeClass("active");
                $(".contentNav ul li a[title='" + pageType + "']").parent().addClass("active");

                if (typeof $("div#listingContainer").data("currentTabHref") === 'undefined')
                {
                    ajaxUrl = $(".contentNav ul li.active a").attr("href");
                } else {
                    ajaxUrl = $("div#listingContainer").data("currentTabHref");
                } 

                var listingType = "";

                if (typeof ajaxUrl !== 'undefined') {
                    listingType = wdot.Utils.GetValueFromQueryStringByKey("ContentPageListingTypeName", ajaxUrl.split('?')[1], true);
                }

                var tabName = $("[data-tabname]").data("tabname");
                if (listingType != tabName)
                {
                    var currentPageType = $(".contentNav ul li a[title='" + pageType + "']");


                    if(currentPageType.attr("href") == undefined) {
                        pageType = pageType.substring(0,1).toUpperCase()+pageType.substring(1);
                        currentPageType = $(".contentNav ul li a[title='" + pageType + "']");
                    } 

                    $("#overview").addClass("hidden");
                    $("#listingContainer").addClass("hidden");
                    $(".contentNav ul li").removeClass("active");
                    currentPageType.parent().addClass("active");
                    var pagingAjaxData =[];
                    ko.utils.arrayForEach($("#listingContainer").find(".pagingContainer").parent().find(".pagination"), function (item) {
                        pagingAjaxData[$(item).attr('data-currentpage-bindingname')] = $(item).attr('data-currentpage');
                    });
                    if (pageType != "Overview") {
                        var xhr = Wdot.Ajax.prototype.Get(currentPageType.attr("href") + "&" + $.address.queryString(), null, function (d) {
                            $('#listingContainer').html(d);
                            $("#listingContainer").removeClass("hidden");
                        });
                        ajaxArray.push(xhr);
                    }
                    else {
                        $("#overview").removeClass("hidden");
                    }
                }
                isInit = false;
            } else {
                isInit = false;
            }
        }
        isInit = false;
        directoryLoadedOnPageLoad  = false;
    };

    var ajaxArray = [];
    $.address.strict(false);
    $.address.externalChange(function (e) {
        if(!directoryLoadedOnPageLoad)
            loadDirectory(e);

        directoryLoadedOnPageLoad = false;
    });

    if (!directoryLoadedOnPageLoad && isInit && window.location.hash != undefined && window.location.hash != '') {
        loadDirectory();
        directoryLoadedOnPageLoad = true;
    } else {
        directoryLoadedOnPageLoad = false;
    }

    $(".featureContent").on("click", "a.pagingbtn", function () {
        var btn = $(this);
        var pagingContainer = btn.parents(".pagination");
        if (btn.attr('data-pagenumber') != "") {
            pagingContainer.attr('data-currentpage', btn.attr('data-pagenumber'));
            var pagingAjaxData = {};
            ko.utils.arrayForEach(pagingContainer.parent().find(".pagination"), function (item) {
                pagingAjaxData[$(item).attr('data-currentpage-bindingname')] = $(item).attr('data-currentpage');
            });
            var ajaxUrl = '';
            if ($("div#listingContainer").data("currentTabHref") == undefined)
            {
                ajaxUrl = $(".contentNav ul li.active a").attr("href");
            } else {
                ajaxUrl = $("div#listingContainer").data("currentTabHref");
            }
            var listingType = "";

            if (typeof ajaxUrl !== 'undefined') {
                listingType = wdot.Utils.GetValueFromQueryStringByKey("ContentPageListingTypeName", ajaxUrl.split('?')[1], true);
            }
            Wdot.Ajax.prototype.Get(ajaxUrl, pagingAjaxData, function (d) {
                $('#listingContainer').html(d);
            });
        }
        return false;
    });

    /*$(".js-favorites").click(function () {
        var isUserLoggedIn = $('.js-user-logged-in').val().trim();
        showLoginOrTripGroupPopUp(isUserLoggedIn, $(this).attr("data-content-type"), $(this).attr("data-id"), $(this), $(this).parents('.js-favorites-parent').find('.js-session-favorite-prompt'));
    });*/

    $(".contentNav ul li a[href='#overview']").click(function () {
        if ($("div#overview").is(':hidden')) {
            $("div#listingContainer").addClass("hidden");
            $("div#overview").removeClass("hidden");
            $(".contentNav ul li.active").removeClass("active");
            $(this).parent().addClass("active");
            wdot.AjaxStateHandler.UpdateHistory("/Overview");
        }
        return false;
    });

    $(".contentNav ul li a[href!='#overview']").click(function () {
        var self = this;
        var tabName = $(self).text();
        $(".contentNav ul li.active").removeClass("active");
        $(self).parent().addClass("active");
        $("div#listingContainer").addClass("hidden");
        var contentType = encodeURI(tabName);
        if ($("div#listingContainer").data("currentTab") != undefined) {
            if ($("div#listingContainer").data("currentTab") != tabName) {
                $("div#listingContainer").data("currentTab", tabName);
                var href = $(self).attr("href");
                $("div#listingContainer").data("currentTabHref", href);
                wdot.AjaxStateHandler.UpdateHistory("/" + contentType   +"?NearByItems.CurrentPage=1&AssignedItems.CurrentPage=1");
                $("div#overview").addClass("hidden");
                Wdot.Ajax.prototype.Get(href, {}, function (d) {
                    
                    $("div#listingContainer").removeClass("hidden");
                    $("div#listingContainer").html(d);
                });
            } else {
                $("div#overview").addClass("hidden");
                $("div#listingContainer").removeClass("hidden");
                var pagingAjaxData =[];
                ko.utils.arrayForEach($("#listingContainer").find(".pagingContainer").parent().find(".pagination"), function (item) {
                    pagingAjaxData[$(item).attr('data-currentpage-bindingname')] = $(item).attr('data-currentpage');
                });
                wdot.AjaxStateHandler.UpdateHistory("/" + contentType+ '?' + $.param(pagingAjaxData));
            }
        }
        return false;
    });

    //Check for book online button on places
    if ($('a.bookOnline').length > 0) {
        $('li.buttons').removeClass('noBook');
    }

    //if close button is clicked
    $('.pop-up .close').click(function (e) {
        //Cancel the link behavior
        e.preventDefault();
        $('.pop-up').fadeOut(500);
    });

});